<template>
  <div>
    <Spinner v-if="this.loading" />
    <Topbar>
      <div class="actions">
        <a href="#" class="important" v-on:click="logout">
          <span>Logout</span>
        </a>
      </div>
    </Topbar>
    <h1>Cabinet</h1>
  </div>
</template>

<script>
import Api from '@/utils/api'
import Topbar from '@/components/Topbar'
import Spinner from '@/components/Spinner'

export default {
  name: 'Cabinet',
  data() {
    return {
      user: {},
      loading: true
    }
  },
  methods: {
    logout: function() {
      this.loaded = true

      Api.delete("auth/")
        .then((response) => {
          if(response.ok) {
            this.loaded = false
            Api.getInstance().catchStatus(401) // HACK: emulate API 401 status
          }
        })
    }
  },
  mounted() {
    this.loading = true

    let self = this
    setTimeout(() => {
      console.log("time is out")
      self.loading = false
    }, 2000)
  },
  components: {
    Topbar,
    Spinner
  }
}
</script>
